import React, { useState } from "react"
import styled from 'styled-components'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faFile, faSpinner } from "@fortawesome/free-solid-svg-icons";

import produce from 'immer'

import { colors, files } from '../../settings'

import { uploadImage, uploadFile } from '../../services/server'
import { generateThumbnails } from '../../utilities/thumbnails'

const Button = styled.div`
    background-color: ${colors.blue};
    border: 0px;
    color: white;
    font-size: 18px;
    border-radius: 4px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 60%;
    text-align: center;
    
    &[disabled] {
        opacity: .8;
        cursor: not-allowed;
    }
    &:hover {
        background-color: ${colors.blueHover};
    }

    svg {
        margin-right: 5px;
    }
`
const Input = styled.input`
    position: absolute;
    left: 0px;
    height: 100%;
    right: 0px;
    bottom: 0px;
    opacity: 0;
`
const Form = styled.div`
    position: relative;
`

const ErrorMessage = styled.div`
    color: red;
`

function getInputFileProps(input, props) {
    const file = input.files[0];
    const data = {}

    data.name = file.name;
    data.size = file.size;
    data.dimensions = {
        width: props.width,
        height: props.height
    }

    return data;
}

function UploadButton(props) {
    const [thumbnailData, setThumbnailData] = useState({ thumbnail: "", preview: "" });
    const [upload, setUpload] = useState({
        progress: 0,
        uploading: false,
        error: "",
        data: { id: 0 }
    });

    function onImageFileSelected(inputControl) {
        setUpload(produce(draft => {
            draft.uploading = true;
            draft.error = null;
            draft.progress = 0;
        }));

        let options = {
            thumbnailSize: props.thumbnailSize || 600,
            preview: false,
            previewSize: props.previewSize || 200,
            format: props.format || "png"
        }

        generateThumbnails(inputControl, options, (data) => {
            if (props.onThumbnails) {
                props.onThumbnails({ filename: inputControl.files[0].name, ...data});
            }
            else {
                uploadImage(
                    props.uploadUrl, 
                    props.ignoreOriginal ? getInputFileProps(inputControl, data.thumbnailProps) : inputControl, 
                    data.thumbnail, 
                    data.preview, 
                    function(percent) {
                        //nada
                    },
                    function(response) {
                        props.onUpload(response);
                        //graphicsMgr.addNewFaceArt(data.faceArt);

                        setUpload(produce(draft => {
                            draft.uploading = false;
                            draft.error = null;
                            draft.progress = 100;
                        }));
                    },
                    function(error) {
                        setUpload(produce(draft => {
                            draft.uploading = false;
                            draft.error = error;
                            draft.progress = 100;
                        }));
                    },
                    function() {

                    }
                );
            }
        });
    }

    function onOtherFileSelected(inputControl) {
        setUpload(produce(draft => {
            draft.uploading = true;
            draft.error = null;
            draft.progress = 0;
        }));

        uploadFile(
            props.uploadUrl, 
            "file",
            inputControl.files[0].name,
            inputControl.files[0],
            function(percent) {
                // nada
            },
            function(response) {
                props.onUpload(response);
                //graphicsMgr.addNewFaceArt(data.faceArt);

                setUpload(produce(draft => {
                    draft.uploading = false;
                    draft.error = null;
                    draft.progress = 100;
                }));
            },
            function(error) {
                setUpload(produce(draft => {
                    draft.uploading = false;
                    draft.error = error;
                    draft.progress = 100;
                }));
            },
            function() {

            }
        );
    }

    function onFileSelected(event) {
        const filename = event.target.value;

        if (filename != "") {
            let ext = filename.substr(filename.length - 3, 3).toLowerCase();
            let inputControl = event.target;

            if (ext === "jpeg") ext = "jpg";

            if (inputControl.files[0].size > files.MAX_UPLOAD_SIZE) {
                setUpload(produce(draft => {
                    draft.uploading = false;
                    draft.error = "Selected file is too large.  Max file size is " + (files.MAX_UPLOAD_SIZE/1024/1024) + "MB.";
                    draft.progress = 0;
                }));
                return;
            }

            let validExt = true;

            if (props.extensions) {
                validExt = false;

                for (const extension of props.extensions) {
                    if (extension.toLowerCase() == ext) {
                        validExt = true;
                    }
                }
            }
            
            //if (ext === "jpg" || ext === "peg" || ext === "png" || ext === "eps" || ext === "pdf" || ext === "svg") {
            if (validExt) {
                if (ext === "jpg" || ext === "png")
                    onImageFileSelected(inputControl)
                else
                    onOtherFileSelected(inputControl)

            } else {
                setUpload(produce(draft => {
                    draft.error = "Only " + (props.extensions.join(",")) + " files are suported.";
                }));
            }
        } 
        else {
            setUpload(produce(draft => {
                draft.error = "Invalid file.";
            }));
        }
    }

    return (
        <>
            <Form className="form" style={props.style}>
                <Button disabled={upload.uploading || props.uploading} style={props.buttonStyle}>
                    <FontAwesomeSvgIcon icon={upload.uploading || props.uploading ? faSpinner : faFile} spin={upload.uploading} />
                    {upload.uploading ? "Uploading...":"Select File"}
                </Button>

                <Input name="image" type="file" onChange={onFileSelected} />
                <input type="hidden" name="thumbnailData" />
                <input type="hidden" name="previewData" />
            </Form>
            { upload.error && 
                <ErrorMessage>{upload.error}</ErrorMessage>
            }
        </>
    );
}

export default UploadButton