import React, { useState, useEffect } from "react"
import styled from "styled-components"
import queryString from 'query-string'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faShapes, faFolder, faFile } from "@fortawesome/free-solid-svg-icons"
import produce from 'immer'

import Card from '../../components/layout/Card';
import ExpansionPanel from '../../components/layout/ExpansionPanel';
import AccountPage from "../../components/layout/AccountPage"
import SignList from "../../components/SignList"
import MajorError from "../../components/MajorError"
import GoogleImage from "../../components/GoogleImage";
import UploadButton from "../../components/buttons/UploadButton"

import { postData } from "../../services/server"
import { storage } from '../../settings'

const Details = styled.div`
	margin-top: 15px;

	h3 {
		margin: 5px 0px;
    	font-size: 13px;
	}
	table {
		font-size: 80%;
		th {
			padding: 8px;
			width: 130px;
			border-radius: 3px;
			background-color: #eee;
			border-bottom: 2px solid white;
			font-weight: normal;
		}
		td {
			padding: 8px;
		}
	}
`
const Files = styled.div`
	> div {
		display: flex;
		align-items: center;
		padding: 5px 0px;
		border-bottom: 1px solid #eee;

		div.icon {
			width: 25px;
			height: 25px;
			overflow: hidden;
		}
	}
`
const UploadFile = styled.div`
	margin-top: 20px;

	div.form {
		width: 200px;

		> div {
			margin: 0px;
		}
	}
`

const ProjectPage = () => {
  const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "");
  const projectUid = params.uid;

  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(false);

  useEffect(
    () => {
        setLoading(true);

        postData("/api/projects/data", { uid: projectUid },
          function(result) {
			setProject(result);
          },
          function(error) {
              setError(error);
          },
          function() {
              setLoading(false);
          }
        );
    }, 
    [projectUid]
  );

  return (
	<AccountPage 
		page="projects" 
		title={project ? "Project #"+ project.id : "Project Details"}
		breadcrumb={[
			{url: "/", title: "Home"},
			{url: "/account/projects/", title: "Projects"},
			{url: null, title: "Project"}
		]}
		loading={loading} 
	>
        <>
		  <Card style={{marginBottom: "15px"}}>
			<Details>
				{project && 
					<>
					<table>
						<tbody>
						<tr>
							<th>Title</th><td>{ project.title }</td>
						</tr>
						<tr>
							<th>Description</th><td><div dangerouslySetInnerHTML={{__html: project.description}} /></td>
						</tr>
						</tbody>
					</table>
					<div dangerouslySetInnerHTML={{__html: project.details}} />
					<table>
						<tbody>
						<tr>
							<th>Date Requested</th><td>{ project.requested_date_formatted }</td>
						</tr>
						</tbody>
					</table>
					</>
				}
			</Details>
		  </Card>

		  <ExpansionPanel 
		  	expanded={true}
			icon={faShapes}
			title="Signs"
		  >
			{(project && project.signs && project.signs.length > 0) ?
				<SignList 
					from="myprojects"
					signs={project.signs}
					border={1}
					onChanged={(signData) => {
						setProject(produce(draft => {
							const index = draft.signs.findIndex(s => s.id == sign.id)
	
							if (index != -1)
							draft.signs[index] = signData
						}));        
					}}
				/>
				:
				<p>No signs found for this project.</p>
			}	
		  </ExpansionPanel>

		  <ExpansionPanel
		  	icon={faFolder}
			title="Resource Files"
		  >
			<>
				{(project && project.files && project.files.length > 0) ?
					<Files>
						{project.files.map(file => (
							<div>
								<div className="icon">
									{file.url_thumb ? 
										<GoogleImage root src={file.url_thumb} required lazy style={{height: "25px", width: "25px"}} />
										:
										<FontAwesomeSvgIcon icon={faFile} size="2x" />
									}
								</div>
								&nbsp;
								<a href={storage.url + file.url} target="_blank">
									{file.filename}
								</a>
							</div>
						))}
					</Files>
					:
					<p>No files found for this project.</p>
				}	
				{project && 
					<UploadFile>
						<p>
							Upload a New File:
						</p>
						<UploadButton 
							uploadUrl={"/api/projects/upload?uid="+project.uid}
							format="jpg"
							onUpload={(upload) => {
								setProject(produce(draft => {
									draft.files.push(upload)
								}));
							}}
						/>
					</UploadFile>
				}
			</>
		  </ExpansionPanel>
        </>
		{(error && error.length>0) && 
			<MajorError 
				error={error} 
				log={error}
				onClose={() => setError("")}
			/>
        }
    </AccountPage>
  )
}

export default ProjectPage