import React, { useState } from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

import { colors, devices } from "../../settings"
import { titleCase } from "../../utilities/utils"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"

const Panel = styled.div`
    background-color: white;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 5px;

    h3 {
        font-size: 120%;
        margin: 0px;
        padding: 10px;
        font-weight: normal;
        cursor: pointer;
        border-bottom: 1px solid #eee;

        background-color: ${props => props.color || "white"};
        color:  ${props => props.color ? "white" : "black"};

        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
            margin-right: 10px;
        }
    }

    &.expanded {
        margin-bottom: 8px;

        > div {
            display: block;
        }
    }

    > div {
        padding: 10px;
        display: none;
    }
`

export default (props) => {
  const [expanded, setExpanded] = useState(props.expanded);

  function toggle() {
    if (props.onChange)
        props.onChange(!expanded);

    setExpanded(!expanded);
  }

  return (
        <Panel className={expanded ? "expanded":""} style={props.style} color={props.color}>
            <h3 onClick={toggle}>
                <div>
                    {props.icon && 
                        <FontAwesomeSvgIcon icon={props.icon} />
                    }
                    {props.title}
                </div>

                <FontAwesomeSvgIcon icon={expanded ? faCaretUp : faCaretDown} />
            </h3>
            <div>
                {props.children}
            </div>
        </Panel>
    )
}